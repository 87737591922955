<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            template(v-if="status_detail.status === 'loading' || status_profile_identity.status === 'loading'")
                h2.text-xl -
            template(v-else-if="profile_identity.ktp && profile_identity.ktp.length > 0")
                h2.text-xl {{ profile_identity.ktp ? profile_identity.ktp : '-' }}
            template(v-else)
                h2.text-xl -
            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-default(to="/query-by-nik")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back

        .card__body.p-4.pt-0
            .card
                .card__header
                    h2.text-xl Personal Information
                .card__body
                    template(v-if="status_detail.status === 'loading' || status_profile_identity.status === 'loading'")
                        Loading(show="true")
                    template(v-else-if="profile_identity.ktp && profile_identity.ktp.length > 0")
                        .info-targets
                            .rollout
                                span.text-xs Name
                                p.text-light.uppercase
                                    | {{ profile_identity.name ? profile_identity.name : '-' }}
                                    //- router-link.link-text(v-if="profile_identity.name && profile_identity.name.length > 0" :to="'/search-by-name/'+ encrypt(profile_identity.name, 'name')" target="_blank")
                                    //-     | {{ profile_identity.name }}
                                    //- template(v-else) -
                            .rollout
                                span.text-xs Gender
                                p.text-light.uppercase {{ profile_identity.gender ? profile_identity.gender : '-' }}
                            .rollout
                                span.text-xs Religion
                                p.text-light.uppercase {{ profile_identity.religion ? profile_identity.religion : '-' }}
                            .rollout
                                span.text-xs Marital
                                p.text-light.uppercase {{ profile_identity.marital_status ? profile_identity.marital_status : '-' }}
                            .rollout
                                span.text-xs Job
                                p.text-light.uppercase {{ profile_identity.occupation ? profile_identity.occupation : '-' }}
                            .rollout
                                span.text-xs Blood Type
                                p.text-light.uppercase {{ profile_identity.blood ? profile_identity.blood : '-' }}
                            
                            .rollout
                                span.text-xs DOB
                                p.text-light.uppercase {{ profile_identity.birth_date ? profile_identity.birth_date : '-' }}
                            .rollout
                                span.text-xs POB
                                p.text-light.uppercase {{ profile_identity.birth_place ? profile_identity.birth_place : '-' }}
                            .rollout
                                //- span.text-xs KTP/NIK
                                span.text-xs Citizen ID
                                p.text-light.uppercase {{ profile_identity.ktp ? profile_identity.ktp : '-' }}
                            .rollout
                                //- span.text-xs NKK
                                span.text-xs Family ID
                                p.text-light.uppercase {{ profile_identity.family_id ? profile_identity.family_id : '-' }}
                            .rollout
                                span.text-xs Mother
                                p.text-light.uppercase {{ profile_identity.mothers_name ? profile_identity.mothers_name : '-' }}
                            .rollout
                                span.text-xs Father
                                p.text-light.uppercase {{ profile_identity.fathers_name ? profile_identity.fathers_name : '-' }}
                            .rollout
                                span.text-xs Status
                                p.text-light.uppercase {{ profile_identity.status ? profile_identity.status : '-' }}
                        
                            .rollout
                                span.text-xs Province
                                p.text-light.uppercase {{ profile_identity.address_province ? profile_identity.address_province : '-' }}
                            .rollout
                                span.text-xs Address
                                p.text-light.uppercase {{ profile_identity.address ? profile_identity.address : '-' }}
                            .rollout
                                span.text-xs City
                                p.text-light.uppercase {{ profile_identity.address_district ? profile_identity.address_district : '-' }}
                            .rollout
                                span.text-xs Sub Distrcit
                                p.text-light.uppercase {{ profile_identity.address_sub_district ? profile_identity.address_sub_district : '-' }}
                            .rollout
                                span.text-xs Village
                                p.text-light.uppercase {{ profile_identity.address_urban_village ? profile_identity.address_urban_village : '-' }}
                            .rollout
                                //- span.text-xs RT/RW
                                span.text-xs NH-ID/CA-ID
                                p.text-light.uppercase {{ profile_identity.rt_rw ? profile_identity.rt_rw : '-' }}
                            .rollout
                                span.text-xs ZIP
                                p.text-light.uppercase {{ profile_identity.zip ? profile_identity.zip : '-' }}

                            //- .rollout
                            //-     span.text-xs Nationality
                            //-     p.text-light.uppercase {{ profile_identity.nationality ? profile_identity.nationality : '-' }}
                            //- .rollout
                            //-     span.text-xs NOK
                            //-     p.text-light.uppercase {{ profile_identity.nok ? profile_identity.nok : '-' }}
                            //- .rollout
                            //-     span.text-xs Print At
                            //-     p.text-light.uppercase {{ profile_identity.print ? profile_identity.print : '-' }}
                            //- .rollout
                            //-     span.text-xs Zodiac
                            //-     p.text-light.uppercase {{ profile_identity.zodiac ? profile_identity.zodiac : '-' }}
                            
                            //- .rollout
                            //-     span.text-xs Phone
                            //-     template(v-if="profile_identity.phone && profile_identity.phone.length > 0 && profile_identity.phone[0]")
                            //-         ul
                            //-             li.mb-2(v-for="item in profile_identity.phone")
                            //-                 .label-green {{ item }}
                            //-     template(v-else)
                            //-         p.text-gray-600.text-xs.italic Phone number not found
                                    
                    NoDataAvailable(v-else)

            .card
                .card__header
                    h2.text-xl Family Information
                .card__body
                    template(v-if="status_profile_family.status === 'loading'")
                        Loading(show="true")
                    template(v-else-if="profile_identity.ktp && profile_identity.ktp.length > 0")
                        .family-item.p-6(v-for="(family, index) in profile_family" :class="index !== (profile_family.length - 1) ? 'mb-8' : ''")
                            .info-targets
                                .rollout
                                    span.text-xs Name
                                    p.text-light.uppercase
                                        | {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                                        //- router-link.link-text(v-if="family.full_name && family.full_name.length > 0" :to="'/search-by-name/'+ encrypt(family.full_name, 'name')" target="_blank")
                                        //-     | {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                                        //- template(v-else) {{ family.full_name ? family.full_name : '-' }} ({{ family.family_conection ? family.family_conection : '-' }})
                                .rollout
                                    //- span.text-xs KTP/NIK
                                    span.text-xs Citizen ID
                                    p.text-light.uppercase {{ family.nik ? family.nik : '-' }}
                                .rollout
                                    //- span.text-xs NKK
                                    span.text-xs Family ID
                                    p.text-light.uppercase {{ family.family_id ? family.family_id : '-' }}
                                .rollout
                                    span.text-xs Gender
                                    p.text-light.uppercase {{ family.gender ? family.gender : '-' }}
                                .rollout
                                    span.text-xs DOB
                                    p.text-light.uppercase {{ family.birth_date ? family.birth_date : '-' }}
                                .rollout
                                    span.text-xs POB
                                    p.text-light.uppercase {{ family.birth_place ? family.birth_place : '-' }}
                                .rollout
                                    span.text-xs Marital
                                    p.text-light.uppercase {{ family.marital_status ? family.marital_status : '-' }}
                                //- .rollout
                                    span.text-xs Zodiac
                                    p.text-light.uppercase {{ family.zodiac ? family.zodiac : '-' }}

                                .rollout
                                    span.text-xs Province
                                    p.text-light.uppercase {{ family.address_province ? family.address_province : '-' }}
                                .rollout
                                    span.text-xs City
                                    p.text-light.uppercase {{ family.address_district ? family.address_district : '-' }}
                                .rollout
                                    span.text-xs Sub District
                                    p.text-light.uppercase {{ family.address_sub_district ? family.address_sub_district : '-' }}
                                .rollout
                                    //- span.text-xs RT/RW
                                    span.text-xs NH-ID/CA-ID
                                    p.text-light.uppercase {{ family.address_rt ? family.address_rt : '-' }}/{{ family.address_rw ? family.address_rw : '-' }}
                                .rollout
                                    span.text-xs Address
                                    p.text-light.uppercase {{ family.address ? family.address : '-' }}

                                //- .rollout
                                        span.text-xs Phone
                                        template(v-if="family.phone && family.phone.length > 0 && family.phone[0]")
                                            ul
                                                li.mb-2(v-for="item in family.phone")
                                                    .label-green {{ item }}
                                        template(v-else)
                                            p.text-gray-600.text-xs.italic Phone number not found

                    NoDataAvailable(v-else)

</template>

<script>
import IconSet from '@/components/IconSet.vue'
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';

export default {
    name: 'DetailQuerybyNIK',
    components: {
        IconSet,
        Loading,
        NoDataAvailable
    },

    data() {
        return {

        }
    },

    computed:{
        ...mapState('querybyNIK', [
            "status_detail",
            "detail",
        ]),
        ...mapState('profile', [
            'status_profile_identity',
            'status_profile_family',
            'profile_family',
            'profile_identity',
        ]),
        route_name() {
            return this.$route.name;
        },    
        id() {
            return this.$route.params.id;
        },
    },

    watch: {
        route_name(newData, oldData) {
            if (newData === 'query-by-nik-detail') {
                if (oldData !== 'query-by-nik-detail') {
                    this.$store.commit('profile/setProfileIdentity', {});
                }

                this.getData();
            }
        },        
        id() {
            this.getData();
        },
        items_per_page() {
            this.getData();
        },
        profile_identity(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },       
    },

    methods: {
        getData() {
            if (this.route_name === 'query-by-nik-detail') {
                EventEmit.$emit('showLoading', true);
                this.$store.dispatch('profile/setLoading');
                this.$store.dispatch("querybyNIK/getDetail", this.id)
                .then((resp) => {
                if(resp.result == 'success'){

                    let key = resp.data.nik;
                    let type = 'nik';
                    let service = 'default';

                    this.$store.dispatch('profile/getProfileIdentity',[key, type, service])
                .then((resp) => {    
                    if(resp.result == 'success'){
                        EventEmit.$emit('showLoading', false);       
                        this.$store.dispatch('profile/getProfileFamily',[key, type, service])
                    }
                })
                }})
            }
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        // 
    },

    
}
</script>

<style lang="sass">
.info-targets
    display: grid
    grid-template-rows: repeat(5, minmax(0, 1fr))
    grid-template-columns: repeat(4, minmax(0, 1fr))
    grid-auto-flow: column
    
</style>